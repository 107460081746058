(function() {
  const openBtn = document.querySelector('.navigation--desktop .navigation__burger');
  const closeBtn = document.querySelector('.navigation--mobile .navigation__burger');
  const mobileNav = document.querySelector('.navigation--mobile');
  
  openBtn.addEventListener('click', function() {
    mobileNav.style.display = 'flex';
  });
  
  closeBtn.addEventListener('click', function() {
    mobileNav.style.display = 'none';
  });
})();